<template>
  <div id="DataClass">
    <el-form size="mini" :inline="true">
      <el-form-item>
        <el-button-group>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="
              () => {
                state = 'add';
                formShow = true;
              }
            "
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-delete"
            @click="
              remove($constant.method.getArrayValueByKey(selectionTableData))
            "
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            @click="$router.go(0)"
          ></el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="请输入分类名称"
          size="mini"
          v-model="where.className"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          plain
          icon="el-icon-search"
          @click="getList"
          >搜索</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      size="mini"
      border
      :data="tableData"
      @selection-change="
        selection => {
          selectionTableData = selection;
        }
      "
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="ID" type="index" width="50"></el-table-column>
      <el-table-column label="用户名" prop="username"></el-table-column>
      <el-table-column label="分类名称" prop="class_name"></el-table-column>
      <el-table-column label="数据总量" prop="count"></el-table-column>
      <el-table-column
        label="创建时间"
        prop="creation_time"
        width="140"
      ></el-table-column>
      <el-table-column label="操作" width="534">
        <template slot-scope="scope">
          <el-button
            size="mini"
            plain
            @click="
              () => {
                form.id = scope.row.id;
                infoShow = true;
              }
            "
            >查看</el-button
          >
          <el-button
            size="mini"
            type="primary"
            plain
            @click="
              () => {
                classId = scope.row.id;
                importShow = true;
              }
            "
            >上传</el-button
          >
          <el-button
            size="mini"
            type="success"
            plain
            @click="exportAllData(scope.row.id)"
            >导出全部</el-button
          >
          <el-button
            size="mini"
            type="success"
            plain
            @click="exportNotData(scope.row.id)"
            >导出未使用</el-button
          >
          <el-button
            size="mini"
            type="warning"
            plain
            @click="
              () => {
                form.id = scope.row.id;
                state = 'modify';
                formShow = true;
              }
            "
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            @click="remove([scope.row.id])"
            >删除</el-button
          >
          <el-button size="mini" type="info" plain @click="empty(scope.row.id)"
            >清空</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="
        size => {
          where.limit = size;
          getList();
        }
      "
      @current-change="
        page => {
          where.page = page;
          getList();
        }
      "
      :current-page="where.page"
      :page-sizes="$constant.web.pageSizes"
      :page-size="where.limit"
      :pager-count="5"
      layout="total, sizes, prev, pager, next"
      :total="where.total"
    >
    </el-pagination>

    <el-dialog
      title="信息"
      :visible.sync="infoShow"
      :fullscreen="fullscreen"
      width="1200px"
    >
      <Data v-if="infoShow" :classId="form.id"></Data>
    </el-dialog>

    <el-dialog title="导入" :visible.sync="importShow" width="400px">
      <el-upload
        drag
        :show-file-list="false"
        :action="$constant.web.baseURL + '/data_class/import'"
        :data="{
          classId: this.classId
        }"
        :on-success="
          () => {
            $message({
              message: '修改成功',
              type: 'success'
            });
            importShow = false;
            getList();
          }
        "
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </el-dialog>

    <el-dialog title="新增" :visible.sync="formShow" width="350px">
      <el-form size="mini">
        <el-form-item>
          <el-input
            size="mini"
            placeholder="请输入分类名称"
            v-model="form.className"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            size="mini"
            type="primary"
            plain
            @click="add"
            v-if="state === 'add'"
            >提交</el-button
          >
          <el-button
            size="mini"
            type="primary"
            plain
            @click="modify"
            v-if="state === 'modify'"
            >提交</el-button
          >
          <el-button size="mini" @click="formShow = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Data from "./Data";
export default {
  name: "DataClass",
  components: { Data },
  data() {
    return {
      fullscreen: false,
      state: "",
      classId: "",
      importShow: false,
      infoShow: false,
      formShow: false,
      form: {
        id: "",
        userId: this.$constant.method.getUserInfo()["id"],
        className: ""
      },
      where: {
        id: this.$constant.method.getUserInfo()["id"],
        className: "",
        page: 1,
        limit: 0,
        total: 0
      },
      tableData: [],
      selectionTableData: []
    };
  },
  mounted() {
    if (window.screen.width <= 600) this.fullscreen = true;
    this.getList();
  },
  methods: {
    // 增加
    add() {
      if (!this.form.className) {
        this.$message.error("请完善表单");
        return false;
      }
      this.$http({
        url: "/data_class/add",
        method: "POST",
        data: this.form
      }).then(() => {
        this.$message({
          message: "添加成功",
          type: "success"
        });
        this.formShow = false;
        this.form["id"] = "";
        this.form["className"] = "";
        this.getList();
      });
    },

    // 删除
    remove(ids) {
      this.$confirm("确定删除").then(() => {
        this.$http({
          url: "/data_class/remove",
          method: "POST",
          data: {
            ids: ids
          }
        }).then(() => {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.getList();
        });
      });
    },

    // 修改管理员
    modify() {
      if (!this.form.className) {
        this.$message.error("请完善表单");
        return false;
      }
      this.$http({
        url: "/data_class/modify",
        method: "POST",
        data: this.form
      }).then(() => {
        this.$message({
          message: "修改成功",
          type: "success"
        });
        this.formShow = false;
        this.getList();
      });
    },

    // 得到数据列表
    getList() {
      this.$http({
        url: "/data_class/list",
        method: "POST",
        data: this.where
      }).then(response => {
        this.where.total = response.count;
        this.tableData = response.list;
      });
    },

    // 清空
    empty(classId) {
      this.$confirm("确定清空").then(() => {
        this.$http({
          url: "/data_class/empty",
          method: "POST",
          data: {
            classId: classId
          }
        }).then(() => {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.getList();
        });
      });
    },

    // 导出全部
    exportAllData(classId) {
      window.location.href =
        this.$constant.web.baseURL + "/data_class/export?classId=" + classId;
    },

    // 导出未使用
    exportNotData(classId) {
      window.location.href =
        this.$constant.web.baseURL +
        "/data_class/export_not?classId=" +
        classId;
    }
  }
};
</script>

<style scoped></style>
