<template>
  <div id="Data">
    <el-form size="mini" :inline="true">
      <el-form-item>
        <el-button-group>
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="
              () => {
                state = 'add';
                formShow = true;
              }
            "
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-delete"
            @click="
              remove($constant.method.getArrayValueByKey(selectionTableData))
            "
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            @click="getList"
          ></el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-input
          placeholder="请输入数据内容"
          size="mini"
          v-model="where.value"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          size="mini"
          type="primary"
          plain
          icon="el-icon-search"
          @click="getList"
          >搜索</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      size="mini"
      border
      :data="tableData"
      @selection-change="
        selection => {
          selectionTableData = selection;
        }
      "
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="#">
        <template slot-scope="scope">
          <span>{{ scope.$index + 1 + where.limit * (where.page - 1) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="状态" width="70">
        <template slot-scope="scope">
          <el-tag size="mini" type="success" v-if="scope.row.obtain === 1"
            >已提取</el-tag
          >
          <el-tag size="mini" type="warning" v-else>未提取</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="数据" prop="value" width="400"></el-table-column>
      <el-table-column
        label="备注"
        prop="remarks"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        label="添加时间"
        prop="add_time"
        width="140"
      ></el-table-column>
      <el-table-column label="操作" width="258">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            @click="
              () => {
                id = scope.row.id;
                value = scope.row.value;
                valueShow = true;
              }
            "
            >修改数据</el-button
          >
          <el-button
            size="mini"
            type="primary"
            plain
            @click="
              () => {
                id = scope.row.id;
                remarks = scope.row.remarks;
                remarksShow = true;
              }
            "
            >修改备注</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            @click="remove([scope.row.id])"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="
        size => {
          where.limit = size;
          getList();
        }
      "
      @current-change="
        page => {
          where.page = page;
          getList();
        }
      "
      :current-page="where.page"
      :page-sizes="$constant.web.pageSizes"
      :page-size="where.limit"
      :pager-count="5"
      layout="total, prev, pager, next"
      :total="where.total"
    >
    </el-pagination>

    <el-dialog
      title="修改数据"
      :visible.sync="valueShow"
      width="350px"
      append-to-body
    >
      <el-form size="mini">
        <el-form-item>
          <el-input
            size="mini"
            placeholder="请输入数据"
            v-model="value"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="modifyValue"
            >提交</el-button
          >
          <el-button size="mini" plain @click="valueShow = false"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="修改备注"
      :visible.sync="remarksShow"
      width="350px"
      append-to-body
    >
      <el-form size="mini">
        <el-form-item>
          <el-input
            size="mini"
            placeholder="请输入备注"
            v-model="remarks"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="modifyRemarks"
            >提交</el-button
          >
          <el-button size="mini" plain @click="remarksShow = false"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="新增"
      :visible.sync="formShow"
      width="350px"
      append-to-body
    >
      <el-form size="mini">
        <el-form-item>
          <el-input
            size="mini"
            placeholder="请输入数据内容"
            v-model="form.value"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            size="mini"
            placeholder="请输入备注"
            v-model="form.remarks"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="add"
            >提交</el-button
          >
          <el-button size="mini" @click="formShow = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Data",
  props: ["classId"],
  data() {
    return {
      state: "",
      formShow: false,
      remarksShow: false,
      valueShow: false,
      form: {
        classId: "",
        value: "",
        remarks: ""
      },
      where: {
        classId: "",
        value: "",
        page: 1,
        limit: 10,
        total: 0
      },
      id: "",
      value: "",
      remarks: "",
      tableData: [],
      selectionTableData: []
    };
  },
  mounted() {
    this.form.classId = this.classId;
    this.where.classId = this.classId;
    this.getList();
  },
  methods: {
    // 增加
    add() {
      if (!this.form.value || !this.form.remarks) {
        this.$message.error("请完善表单");
        return false;
      }
      this.$http({
        url: "/data/add",
        method: "POST",
        data: this.form
      }).then(() => {
        this.$message({
          message: "添加成功",
          type: "success"
        });
        this.formShow = false;
        this.form["value"] = "";
        this.form["remarks"] = "";
        this.getList();
      });
    },

    // 删除
    remove(ids) {
      this.$confirm("确定删除").then(() => {
        this.$http({
          url: "/data/remove",
          method: "POST",
          data: {
            ids: ids
          }
        }).then(() => {
          this.$message({
            message: "删除成功",
            type: "success"
          });
          this.getList();
        });
      });
    },

    // 修改备注
    modifyRemarks() {
      if (!this.remarks) {
        this.$message.error("请完善表单");
        return false;
      }
      this.$http({
        url: "/data/modify_remarks",
        method: "POST",
        data: {
          id: this.id,
          remarks: this.remarks
        }
      }).then(() => {
        this.$message({
          message: "修改成功",
          type: "success"
        });
        this.remarksShow = false;
        this.getList();
      });
    },

    // 修改数据
    modifyValue() {
      if (!this.value) {
        this.$message.error("请完善表单");
        return false;
      }
      this.$http({
        url: "/data/modify_value",
        method: "POST",
        data: {
          id: this.id,
          value: this.value
        }
      }).then(() => {
        this.$message({
          message: "修改成功",
          type: "success"
        });
        this.valueShow = false;
        this.getList();
      });
    },

    // 得到数据列表
    getList() {
      this.$http({
        url: "/data/list",
        method: "POST",
        data: this.where
      }).then(response => {
        this.where.total = response.count;
        this.tableData = response.list;
      });
    }
  }
};
</script>

<style scoped></style>
